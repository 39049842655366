<!--
 * @Description: 
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2021-12-22 21:27:27
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-10 15:03:12
-->
<template >
  <el-breadcrumb
    separator-class="el-icon-arrow-right"
    v-if="$route.meta.breadcrumb && $route.meta.breadcrumb.length > 0"
  >
    <el-breadcrumb-item
      v-for="(item, index) in $route.meta.breadcrumb"
      :key="item.path"
      :to="{ path: item.path }"
      v-show="$route.params.moduleName == item.type || !item.type"
    >
      <!-- 用插槽的写法，如果用v-text会造成不显示箭头 -->
      <template v-if="index < $route.meta.breadcrumb.length - 1">{{item.text}}</template>
      <template v-else>{{typeText}}{{item.text }}</template>
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>
<script>
export default {
  data() {
    return {
      typeText: '',
    }
  },
  watch: {
    $route: {
      handler(to) {
        if (to.params) {
          if (to.params.type == 'create') {
            this.typeText = '新增'
          } else if (to.params.type == 'edit') {
            this.typeText = '編輯'
          }else if (to.params.type == 'copy') {
            this.typeText = '快速創建'
          } else {
            this.typeText = ''
          }
        }
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss">
/* 样式有点问题 */
.el-breadcrumb {
  background: #fff;
  padding-left: 20px;
  margin-left: 0px !important;
  line-height: 40px;
  position: fixed;
  top: 50px;
  left: 162px;
  right: 0;
  z-index: 3;
  box-shadow: 3px 2px 8px rgba(0, 0, 0, 0.1)
}
</style>