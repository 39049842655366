<!--
 * @Description: 
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2022-01-21 22:05:18
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-09 20:17:41
-->
<template>
  <el-header class="header">
    <div class="c-logo">
      Swapifly管理
    </div>
    <div class="c-header-txt">
      <!-- slogan -->
    </div>
    <div class="c-header-right">
      <!-- 下拉框 -->
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          <span class="c-uname thidden">
            {{userInfo.userName}}
          </span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="out">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </el-header>
</template>
<script>
import auth from "@/utils/auth";
export default {
  methods: {
    handleCommand() {
      auth.remove();
      this.$router.push("/login");
      this.api.system.logout()
    }
  },
  created() {
    let authInfo = auth.get();
    Object.assign(this.userInfo, authInfo);
  },
  data() {
    return {
      userInfo: {}
    };
  }
};
</script>
<style lang="scss" scoped>
.el-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  z-index: 5;
  background-color: #ffffff;
  color: #303030;
  height: 50px !important;
  border: 1px solid rgba(227, 232, 241, 1);
  font-family: PingFangSC-Medium, PingFang SC;
  display: flex;
}
.c-header-con {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.c-logo {
  flex: 0 0 160px;
  border-right: 1px solid #e3e8f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 90px;
    height: 30px;
  }
}
.c-header-txt {
  font-size: 22px;
  font-weight: bold;
  color: #6876a3;
  padding-left: 20px;
  border-right: 1px solid #e3e8f1;
  flex: 1;
  display: flex;
  align-items: center;
}
.c-header-right {
  display: flex;
  flex:0 0 160px;
  padding-left: 20px;
  align-items: center;
  // justify-content: flex-end;
}
.c-uname{
  display: inline-block;
  max-width: 120px;
}
.el-icon-arrow-down{position: relative; top: -3px;}
</style>
