<template >
  <div class="l-container">
    <!-- 头部 -->
    <bjxHeader />
    <!-- 侧边栏 -->
    <bjxAside />
    <!-- 面包屑 -->
    <breadcrumbs />
    <!-- 主内容 -->
    <router-view></router-view>
  </div>
</template>

<script>
import bjxAside from "./components/aside";
import bjxHeader from './components/l-header';
import breadcrumbs from "./components/bread";
// import container from "./components/container";
let urls = require("@/config");
export default {
  name: "layout",
  components: {
    bjxAside,
    bjxHeader,
    // container,
    breadcrumbs
  },
  data() {
    return {
      s: ''
    }
  },
  created() {
    this.s = urls[process.env.NODE_ENV];
  }
};
</script>

