<template>
  <el-aside class="l-aside">
    <el-menu
      router
      :default-active="defaultActive"
      background-color="#344155"
      text-color="#fff"
      active-text-color="#02A7F0"
    >
      <!-- 有二级菜单 -->
      <template v-for="(item, index) in sliders">
        <template v-if="item.children && item.children.length > 0">
          <el-submenu :index="item.path" :key="index">
            <template slot="title">
              <!-- <i class="iconfont icon-bianzu129" ></i> -->
              <!-- <svg class="icon" aria-hidden="true">
                <use :xlink:href="`#icon${item.icon}`" />
              </svg> -->
              <span>{{ item.text }}</span>
            </template>
            <el-menu-item
              v-for="child in item.children"
              :key="child.path"
              :index="child.path"
              >{{ child.text }}</el-menu-item
            >
          </el-submenu>
        </template>

        <!-- 没有二级菜单 -->
        <!-- 应该是只有首页没有二级菜单 -->
        <template v-else>
          <el-menu-item :index="item.path" :key="item.path">
            <!-- <svg class="icon" aria-hidden="true">
              <use :xlink:href="`#icon${item.icon}`" />
            </svg> -->
            <span slot="title" v-text="item.text"></span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </el-aside>
</template>
<script>
export default {
  data() {
    return {
      // 默认显示的页面
      defaultActive: '/ads',
      sliders: [
        // {
        //   path: "/home",
        //   text: "首頁",
        //   icon: "shouye",
        // },
        {
          path: '/ads',
          text: '廣告管理',
          // icon: 'dianpumokuai',
          children: [
            {
              path: '/ads',
              text: '首頁橫幅',
            },
            {
              path: '/ads/goodsCats',
              text: '商品分類橫幅',
            },
            {
              path: '/ads/hotBrand',
              text: '熱門品牌配置',
            },
            {
              path: '/ads/googleAds',
              text: 'Google廣告配置',
            },
          ],
        },
        {
          path: '/goods',
          text: '商品管理',
          // icon: 'dianpumokuai',
          children: [
            {
              path: '/goods',
              text: '商品分類',
            },
            {
              path: '/goods/list',
              text: '商品列表',
            },
            {
              path: '/goods/exposure',
              text: '曝光套餐',
            },
            {
              path: '/goods/payment',
              text: '支付記錄',
            },
            {
              path: '/goods/words',
              text: '違禁詞管理'
            }
          ],
        },
        {
          path: '/user',
          text: '用戶管理',
          // icon: 'dianpumokuai',
          children: [
            {
              path: '/user',
              text: '用戶列表',
            },
            {
              path: '/user/merchant',
              text: '註冊商戶',
            },
            {
              path: '/user/notice',
              text: '通知管理',
            },
          ],
        },
        {
          path: '/report/goods',
          text: '舉報管理',
          // icon: 'dianpumokuai',
          children: [
            {
              path: '/report/goods',
              text: '商品舉報記錄',
            },
            {
              path: '/report/user',
              text: '用戶舉報記錄',
            },
          ],
        },
        {
          path: '/help',
          text: '幫助中心管理',
          // icon: 'dianpumokuai',
          children: [
            {
              path: '/help',
              text: '文章主題',
            },
            {
              path: '/help/list',
              text: '文章列表',
            },
          ],
        },
        {
          path: '/news',
          text: '新聞管理',
          // icon: 'dianpumokuai',
          children: [
            {
              path: '/news',
              text: '新聞管理',
            },
          ],
        },
        {
          path: '/statistics',
          text: '數據管理',
          // icon: 'dianpumokuai',
          children: [
            {
              path: '/statistics',
              text: '用戶數據概覽',
            },
            {
              path: '/statistics/user/detail',
              text: '用戶統計',
            },
            {
              path: '/statistics/goods',
              text: '商品數據概覽',
            },
            {
              path: '/statistics/goods/detail',
              text: '商品統計',
            },
          ],
        },

        {
          path: '/system/account',
          text: '系統管理',
          icon: 'xitong',
          children: [
            {
              path: '/system/account',
              text: '管理員列表',
            },
          ],
        },
      ],
    }
  },
  watch: {
    // 监听路由的变化
    $route: {
      handler(to) {
        if (to.meta.menu) {
          this.defaultActive = to.meta.menu
        } else {
          this.defaultActive = to.path
        }
      },
      immediate: true,
    },
  },
  methods: {
    hanlderPush(path) {
      this.$router.push(path)
    },
  },
}
</script>
<style scoped>
.l-aside {
  width: 161px !important;
  position: fixed;
  left: 0;
  top: 50px;
  bottom: 0;
  z-index: 2;
  overflow-x: hidden;
  overflow: auto;
  border-right: 1px solid #e3e8f1;
  background: rgba(48, 65, 86, 1);
}
.el-menu {
  overflow: hidden;
}
/deep/.el-submenu .el-menu-item {
  width: 157px !important;
}
/*  侧边栏小三角*/
/deep/ .el-submenu .el-submenu__icon-arrow {
  right: 6px;
  top: 53%;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  overflow: hidden;
  margin-right: 5px;
}
</style>
